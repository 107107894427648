<template>
  <section class="component-fee-type-undertable-commission">
    <bg-grid>
      <bg-grid-item :col="4">
        <bg-field class="mb-0" label="Tipe Kamar">
          <bg-select
            v-model="value.roomType"
            :options="options.roomType"
            data-testid="undertable-commission-select-room-type"
          />
        </bg-field>
      </bg-grid-item>
      <bg-grid-item :col="4">
        <bg-field class="mb-0" label="Nomor Kamar">
          <bg-select
            v-model="value.roomNumber"
            :options="options.roomNumber"
            data-testid="undertable-commission-select-room-number"
          />
        </bg-field>
      </bg-grid-item>
      <bg-grid-item :col="4">
        <bg-field class="mb-0" label="Nama Penyewa" message="Opsional">
          <bg-input
            v-model="value.tenantName"
            placeholder="Masukkan nama penyewa"
          />
        </bg-field>
      </bg-grid-item>

      <bg-grid-item :col="12" class="mb-0">
        <bg-field
          class="mb-0"
          label="Nominal Pengeluaran"
          :description="formattedPrice"
        />
      </bg-grid-item>
    </bg-grid>
  </section>
</template>

<script>
import { BgGrid, BgGridItem, BgField, BgSelect, BgInput } from 'bangul-vue';
import { rupiahFormatter } from 'Utils/formatter';

export default {
  name: 'FeeTypeCommissionUndertable',

  components: {
    BgGrid,
    BgGridItem,
    BgField,
    BgSelect,
    BgInput,
  },

  props: {
    options: {
      type: Object,
      required: true,
    },
    deductionValue: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      value: {
        roomType: '',
        roomNumber: '',
        tenantName: '',
      },
    };
  },

  computed: {
    roomPrice() {
      if (this.value.roomType) {
        const selected = this.options.roomType.find(
          val => val.val === this.value.roomType
        );
        return selected.price;
      }
      return null;
    },
    formattedPrice() {
      return this.roomPrice ? rupiahFormatter(this.roomPrice) : '-';
    },
    formIsValid() {
      return Boolean(this.value.roomType && this.value.roomNumber);
    },
    clonedValue() {
      return JSON.parse(JSON.stringify(this.value))
    }
  },

  watch: {
    deductionValue() {
      this.value = { ...this.deductionValue };
    },
    clonedValue: {
      handler(newValue, oldValue) {
        if(JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.onTypeIn();
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    rupiahFormatter(value) {
      return rupiahFormatter(value);
    },
    onTypeIn() {
      this.$emit('on-type-in', { ...this.value, price: this.roomPrice, valid: this.formIsValid });
    },
  },
};
</script>
